import React from "react"
import { useTranslation } from "react-i18next"
import Link from "../../utilities/link"

const FooterNav = () => {
  const { t } = useTranslation()
  return (
    <div className="navbar is-transparent">
      <div className="container">
        <div className="navbar-brand">
          <span className="navbar-burger burger" data-target="navbarMenuHeroC">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div id="navbarMenuHeroC" className="navbar-menu">
          <div
            className="navbar is-transparent"
            style={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <span className="navbar-item">
                <Link
                  to="/contatti"
                  title={t("header.ContactTitleTag")}
                  className="button is-bianco"
                >
                  <span> {t("header.Contact")}</span>
                </Link>
              </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterNav
