import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { usePageContext } from '../../pageContext'

const SEO = (props) => {
  const { site, allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            supportedLanguages
            defaultLanguage
          }
        }
        allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              html
              id
              frontmatter {
                path
                lang
                translatedPath
              }
            }
          }
        }
      }
    `
  )

  // const canonical = props.pathname
  //   ? `${site.siteMetadata.siteUrl}${props.pathname}`
  //   : null

  const host = site.siteMetadata.siteUrl;
  const image = props.seoImage
    ? `${host}${props.seoImage}`
    : null

  const { lang, originalPath, localizedPath, splittedPath } = usePageContext();

  return (
    <Helmet
      htmlAttributes={{
          lang,
        }}
      title={props.title}
      meta={[
        { name: 'title', content: props.title },
        { name: 'description', content: props.description },
        {
          property: 'og:title',
          content: props.title,
        },
        {
          property: 'og:url',
          content: `${host}${localizedPath}`,
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:image:secure_url',
          content: image,
        },
        {
          property: 'og:description',
          content: props.description,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          property: 'og:locale',
          content: lang,
        },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: props.title },
        {
          name: 'twitter:description',
          content: props.description,
        },
        {
          name: 'twitter:image',
          content: image,
        },
        { property: 'og:type', content: 'website' },
        {
          name: 'robots',
          content: props.robots ? props.robots : 'index, follow',
        },

        { name: 'twitter:creator', content: '@ctaelectronics' },
        {
          property: 'og:site_name',
          content:
            'CTA Manufacturing - Third Party Contract Manufacturer',
        },
      ]}
      link={
        [
          ///Definiamo l'url canonico (in base alla lingua)
        {
          rel: 'canonical',
          href: `${host}${localizedPath}`,
        },
        /// Definiamo l'url canonico se la lingua dell'utente non è supportata (usiamo come default la lingua inglese)
        ...site.siteMetadata.supportedLanguages.map(supportedLang => {
           
            if (supportedLang === 'en') {

              const supportedLangURL = require(`../../locales/${supportedLang}/urls`)
              
              let completePath = ( originalPath === '' || originalPath === '/') ? '' : splittedPath.map(element => supportedLangURL[element] !== undefined ? supportedLangURL[element] : element).join('/')

              const mdPath = allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.lang === 'en').filter(edge => edge.node.frontmatter.path === originalPath)
              const isMarkdown = mdPath.length > 0 

              if (isMarkdown) {
                completePath = mdPath[0].node.frontmatter.translatedPath
              }

              return (
              
                {
                  rel: 'alternate',
                  hrefLang: 'x-default',
                  href: completePath === '' ? `${host}/en/` : `${host}/en/${completePath}/`,
                }
              )
            } else {
              return (
              
                {}
              )
            }
        }),
       
        /// definiziamo gli url alternativi all'url canonico, cioè gli url - relativi alla stessa pagina - nelle altre lingue esistenti
        ...site.siteMetadata.supportedLanguages.map(supportedLang => {
           
          
                const supportedLangURL = require(`../../locales/${supportedLang}/urls`)
                const translatedURL = supportedLangURL
                let completePath = ( originalPath === '' || originalPath === '/') ? '' : splittedPath.map(element => translatedURL[element] !== undefined ? translatedURL[element] : element).join('/')

                const mdPath = allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.lang === supportedLang).filter(edge => edge.node.frontmatter.path === originalPath)
                const isMarkdown = mdPath.length > 0 

                if (isMarkdown) {
                  completePath = mdPath[0].node.frontmatter.translatedPath
                }

                return (
                
                  ({
                    rel: 'alternate',
                    hrefLang: supportedLang,
                    href: completePath === '' ? `${host}/${supportedLang}/` : `${host}/${supportedLang}/${completePath}/`,
                  })
                )
            
          
        })
        ] 
    }
    >
      <html lang={lang} />
    </Helmet>
  )
}

export default SEO
