import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { usePageContext } from '../pageContext';

const Link = ({ to, ref, ...rest }) => {
  const { lang } = usePageContext();
  const translatedTo = to.split('/')
  const langURL = require(`../locales/${lang}/urls`)
  const translatedURL = langURL
  const completePath = translatedTo.map(element => translatedURL[element] !== undefined ? translatedURL[element] : element).join('/')

  return <GatsbyLink {...rest} to={to === '/' ? `/${lang}/` : `/${lang}${completePath}/`} />;
};

export default Link;