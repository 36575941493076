import React from "react"
import { useTranslation } from "react-i18next"
import Link from '../../utilities/link'
import FooterNav from "./footerNav"

const Footer = () => {

    const { t } = useTranslation()

    return (
      <>
        <footer
          className="footer is-soft-gradient"
          style={{ paddingTop: "120px" }}
        >
          <div className="container is-narrow">
            <div className="columns is-multiline">
              <div className="column is-3">
                <div
                  className="title is-4"
                  style={{
                    borderBottom: "2px solid #fff",
                    paddingBottom: "20px",
                  }}
                >
                  Hera Laundry
                </div>
                <p className="content"><strong>{t("footer.description")}</strong></p>
              </div>
              <div className="column is-1"></div>
              <div className="column is-2">
                <div className="content">
                  <h4 style={{
                    borderBottom: "2px solid #fff",
                    paddingBottom: "25px",
                  }}>{t('header.Products')}</h4>
                  <p>
                  <Link
                      style={{ color: "#4a4a4a", fontWeight: '700' }}
                      to="/lavanderie-industriali/hera-smart"
                      title={t('header.HeraSmartTitleTag')}
                    >
                      {t('header.HeraSmart')}
                    </Link>
                    <br />
                    <Link
                      style={{ color: "#4a4a4a", fontWeight: '700' }}
                      to="/lavanderie-industriali/hera-basic"
                      title={t('header.HeraBasicTitleTag')}
                    >
                      {t('header.HeraBasic')}
                    </Link>
                  </p>
                </div>
              </div>
              <div className="column is-2">
                <div className="content">
                  <h4 style={{
                    borderBottom: "2px solid #fff",
                    paddingBottom: "25px",
                  }}>{t('header.Applications')}</h4>
                  <p>
                  <Link
                      style={{ color: "#4a4a4a", fontWeight: '700'}}
                      to="/smart-dashboard"
                      title={t('header.HeraSmartDashboardTitleTag')}
                    >
                      {t('header.HeraSmartDashboard')}
                    </Link>
                    <br />
                  <Link
                      style={{ color: "#4a4a4a", fontWeight: '700' }}
                      to="/configuratore-lavaggi"
                      title={t('header.HeraWashConfiguratorTitleTag')}
                    >
                      {t('header.HeraWashConfigurator')}
                    </Link>
                  </p>
                </div>
              </div>
              <div className="column is-1"></div>
              <div className="column is-3">
                <div className="content">
                  <h4 style={{
                    borderBottom: "2px solid #fff",
                    paddingBottom: "25px",
                  }}>{t('footer.about')}</h4>
                  <p><strong>{t('footer.aboutDescription')}</strong>
                    </p>
                </div>
              </div>
              <div className="column is-12"></div>
              <div className="column is-12"></div>
              <div className="column is-12"></div>
              <div className="column is-full has-text-centered" style={{backgroundColor: 'RGBA(255,255,255,0.1)', padding: '50px'}}>
                  <div className="columns is-vcentered">
                    <div className="column is-1">
                    <svg xmlns="http://www.w3.org/2000/svg" style={{width: '50px'}} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                    </div>
                    <div className="column is-11">
                    <p>
                        <strong>{t('footer.privacyText')} 
                            <Link
                              style={{ color: "#4a4a4a" }}
                              to="/privacy"
                              title="Privacy Policy"
                            >
                              Privacy Policy
                            </Link>
                        </strong>
                    </p>
                    </div>
                  </div>
              </div>
              <div className="column is-full has-text-centered"></div>
              <div className="column is-full has-text-centered">
                <FooterNav />
              </div>
              <div
                className="column is-full has-text-centered"
                style={{ borderTop: "2px solid #fff", paddingTop: "20px" }}
              >
                <div className="content">
                  <p><strong>
                    <b>Hera Laundry</b> •{" "}
                    <i>{t("footer.description")}</i> •{" "}
                    {t("footer.brand")} <a style={{color: '#363636'}} href="https://www.ctaelectronics.com">CTA Electronics Srl</a> • Copyright{" "}
                    {new Date().getFullYear()} • {t("footer.copyright")}{" "}
                    </strong></p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }

export default Footer