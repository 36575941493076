import React from "react"
import { withTranslation } from "react-i18next"
import LanguagePicker from './languagePicker'
import Link from '../../utilities/link'


class SiteNavigation extends React.Component {
  state = { showMenu: false }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  render() {
    const { t } = this.props
    const menuActive = this.state.showMenu ? "is-active" : ""
    const burgerActive = this.state.showMenu ? "is-active" : ""
    return (
      <header className="navbar is-spaced">
        <div className="container">
          <div className="navbar-brand">
            <Link
              to="/"
              title="Hera Laundry"
              className="font-semibold navbar-item"
            >
              Hera Laundry
            </Link>
            <span
              className={`navbar-burger burger ${burgerActive}`}
              onClick={this.toggleMenu}
              data-target="navbarMenuHeroC"
            >
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroC" className={`navbar-menu ${menuActive}`}>
            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable">
                <span className="font-semibold navbar-link">
                  {t("header.Products")}
                </span>

                <div className="navbar-dropdown">
                  <div className="columns">
                    <div className="column is-12">
                      <p className="font-semibold navbar-item uppercase"><small>{t('header.ForWashingMachines')}</small></p>
                      <hr className="navbar-divider"></hr>
                      <Link to="/lavanderie-industriali/hera-smart" title={t('header.HeraSmartTitleTag')} className="font-semibold navbar-item">{t('header.HeraSmart')}</Link>
                      <hr className="navbar-divider"></hr>
                      <Link to="/lavanderie-industriali/hera-basic" title={t('header.HeraBasicTitleTag')} className="font-semibold navbar-item">{t('header.HeraBasic')}</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <span className="font-semibold navbar-link">
                  {t("header.Applications")}
                </span>
                <div className="navbar-dropdown">
                  <div className="columns">
                    <div className="column is-6">
                      <p className="font-semibold uppercase navbar-item"><small>{t('header.ControlAnalytics')}</small></p>
                      <hr className="navbar-divider"></hr>
                      <Link to="/smart-dashboard" title={t('header.HeraSmartDashBoardTitleTag')} className="font-semibold navbar-item">{t('header.HeraSmartDashboard')}</Link>
                    </div>
                    <div className="column is-6">
                      <p className="font-semibold uppercase navbar-item"><small>{t('header.WashConfigurator')}</small></p>
                      <hr className="navbar-divider"></hr>
                      <Link to="/configuratore-lavaggi" title={t('header.HeraWashConfiguratorTitleTag')} className="font-semibold navbar-item">{t('header.HeraWashConfigurator')}</Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Link
                to="/prezzi"
                title={t("header.Pricing")}
                className="navbar-item"
              >
                <strong>{t("header.Pricing")}</strong>
              </Link> */}
              <Link
                to="/contatti"
                title={t("header.ContactTitleTag")}
                className="navbar-item font-semibold"
              >
                {t("header.Contact")}
              </Link>
              <span className="navbar-item">
                <Link
                  to="/richiedi-demo"
                  title={t("header.DemoRequestTitleTag")}
                  className="navbar-item font-semibold"
                >
                  <span>{t("header.DemoRequest")}</span>
                </Link>
              </span>
              <span className="navbar-item">
                <a
                  href="https://app.heralaundry.com/login"
                  title={t("header.Login")}
                  className="font-semibold button is-info is-outlined"
                >
                  <span>{t("header.Login")}</span>
                </a>
              </span>
              <span className="navbar-item">
                <a
                  href="https://app.heralaundry.com/register-1"
                  title={t("header.Signup")}
                  className="font-semibold button is-primary is-outlined"
                >
                  <span>{t("header.Signup")}</span>
                </a>
              </span>
              <LanguagePicker />
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default withTranslation()(SiteNavigation)
