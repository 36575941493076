import React from 'react';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { usePageContext } from '../../pageContext';
import i18n, { resources } from '../../i18n'

const LanguagePicker = () => {
  const { splittedPath, originalPath } = usePageContext();
 
  const mdSearch = useStaticQuery(
    graphql`
      {
        allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              html
              id
              frontmatter {
                path
                lang
                translatedPath
              }
            }
          }
        }
      }
    `
  );

  return (
    <>

    <div className="navbar-item has-dropdown is-hoverable">
        <span className="navbar-link">
            {resources[i18n.language.split('-')[0]].lang}
        </span>
            <div className="navbar-dropdown">
             {Object.keys(resources).map(supportedLang => {
              
                const supportedLangURL = require(`../../locales/${supportedLang}/urls`)
                const translatedURL = supportedLangURL
                let completePath = splittedPath.map(element => translatedURL[element] !== undefined ? translatedURL[element] : element).join('/')

                const mdPath = mdSearch.allMarkdownRemark.edges.filter(edge => edge.node.frontmatter.lang === supportedLang).filter(edge => edge.node.frontmatter.path === originalPath)
                const isMarkdown = mdPath.length > 0 

                if (isMarkdown) {
                  completePath = mdPath[0].node.frontmatter.translatedPath
                }
        
                return (
                  <GatsbyLink
                  key={supportedLang}
                  className="navbar-item"
                  aria-label={`Change language to ${supportedLang}`}
                  to={completePath === '/' ? `/${supportedLang}/` : `/${supportedLang}/${completePath}/`}
                  >
                  {resources[supportedLang].lang}
                  </GatsbyLink>
                  )
                }   
              )}
        </div>
    </div>
    </>
   );
};

export default LanguagePicker;